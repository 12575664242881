<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="3">
        <v-combobox
          v-model="driver"
          :items="driverItems"
          :item-text="getDriverText"
          label="Driver"
          return-object
          outlined
          class="combobox"
          @change="filterLogsBySendToId(driver)"
        />
      </v-col>

      <v-col cols="12">
        <Table
          :headers="headers"
          :rows="items"
          :loading="isLoading"
        >
        </Table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Table } from '@/components/shared';
import { colors } from '@/util';

export default {
  name: 'SmsLog',
  inject: ['eventHub'],
  components: { Table },
  data() {
    return {
      items: [],
      driver: null,
      driverItems: [],
      isLoading: false,
      colors,
      headers: [
        { text: 'Sent To User', value: 'sendToUser', align: 'start', width: '150px' },
        { text: 'User Number', value: 'sendTo', sortable: false, width: '150px' },
        { text: 'Content', value: 'content', sortable: false },
        { text: 'Sent By', value: 'sendBy', sortable: false },
        { text: 'Sent At', value: 'sentAt', width: '150px' },
        { text: 'Status', value: 'status',width: '150px' },
      ],
    };
  },
  computed: {
    ...mapGetters('smsLog', ['smsLogs', 'smsLogsBySendToId']),
    ...mapGetters('driver', ['drivers']),
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions('smsLog', ['getSmsLogs']),
    async init() {
      this.isLoading = true;
      await this.getSmsLogs();
      const activeDrivers = this.drivers.filter((driver) => driver.active);

      this.driverItems = activeDrivers;
      this.items = this.smsLogs;
      this.isLoading = false;
    },
    filterLogsBySendToId(driver) {
      if (!driver) {
        this.items = this.smsLogs;
        return;
      }
      this.items = this.smsLogsBySendToId[driver?.id] || [];
    },
    getDriverText(driver) {
      return [driver?.firstName, driver?.lastName].join(' ').trim();
    },
  },
};
</script>
