var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "px-8 py-0", attrs: { fluid: "" } },
    [
      _c("bridge-layout", {
        ref: "bridgeLayout",
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function () {
              return [
                _c(
                  "section",
                  { staticClass: "py-4" },
                  [
                    _c(
                      "v-row",
                      { staticClass: "mb-4 mt-2" },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "d-flex py-0",
                            attrs: { cols: "12", md: "6" },
                          },
                          [
                            _c(
                              "v-chip",
                              {
                                staticClass: "button-chip",
                                attrs: { label: "" },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.filteredAssignments.length) +
                                    " " +
                                    _vm._s(
                                      _vm.pluralize(
                                        "Assignment",
                                        _vm.filteredAssignments.length
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "d-flex justify-end py-0",
                            attrs: { cols: "12", md: "6" },
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "my-0 mx-2",
                                attrs: { dark: "", color: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.printAssignmentList()
                                  },
                                },
                              },
                              [_c("v-icon", [_vm._v("mdi-printer")])],
                              1
                            ),
                            _vm.driverConfig.driverDecline ||
                            _vm.driverConfig.driverAccept
                              ? _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "", contained: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "small",
                                                [
                                                  _c(
                                                    "v-chip",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "mx-2 button-chip",
                                                          attrs: {
                                                            outlined: "",
                                                            rounded: "",
                                                            color: "primary",
                                                            "prepend-icon":
                                                              "mdi-information",
                                                          },
                                                        },
                                                        "v-chip",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: {
                                                            color: "blue",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-information"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v("Color Codes"),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1480477795
                                    ),
                                  },
                                  [
                                    _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold green--text text--darken-1",
                                        },
                                        [_vm._v("Driver Accepted")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold blue--text text--darken-2",
                                        },
                                        [
                                          _vm._v(
                                            "Driver Assigned (Pending Action)"
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold orange--text text--darken-3",
                                        },
                                        [_vm._v("No Driver Assigned")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold red--text text--accent-2",
                                        },
                                        [_vm._v("Driver Declined")]
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _c("v-switch", {
                              staticClass: "ma-0 pa-0 mx-4 mt-2 d-inline-block",
                              attrs: {
                                label: "Expand All",
                                "hide-details": "",
                              },
                              model: {
                                value: _vm.allExpanded,
                                callback: function ($$v) {
                                  _vm.allExpanded = $$v
                                },
                                expression: "allExpanded",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-toolbar",
                      { attrs: { height: "50", elevation: "4", rounded: "" } },
                      [_c("AssignmentListMenu")],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "list",
            fn: function () {
              return [
                _c(
                  "div",
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "pa-0 d-flex flex-column justify-center",
                        attrs: { cols: "12" },
                      },
                      [
                        _vm.loading
                          ? _c("v-progress-circular", {
                              staticClass: "progress",
                              attrs: {
                                size: 50,
                                color: "primary",
                                indeterminate: "",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._l(_vm.paginatedAssignments, function (a, i) {
                      return _c("assignment", {
                        key: a.id,
                        staticClass: "card-spacing",
                        attrs: {
                          assignmentProp: a,
                          allExpanded: _vm.allExpanded,
                          groupNext:
                            i + 1 < _vm.paginatedAssignments.length &&
                            _vm.paginatedAssignments[i + 1].tripRequestId ==
                              a.tripRequestId,
                        },
                        on: {
                          refresh: _vm.refresh,
                          adminDecline: _vm.adminDecline,
                        },
                      })
                    }),
                  ],
                  2
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "bottom",
            fn: function () {
              return [
                _c(
                  "v-toolbar",
                  {
                    staticClass: "my-4 d-flex flex-row justify-center",
                    attrs: { height: "50", elevation: "4", rounded: "" },
                  },
                  [
                    _c("v-pagination", {
                      attrs: { length: _vm.totalPages, totalVisible: 10 },
                      on: {
                        input: function ($event) {
                          return _vm.$refs.bridgeLayout.resetScroll()
                        },
                      },
                      model: {
                        value: _vm.currentPage,
                        callback: function ($$v) {
                          _vm.currentPage = $$v
                        },
                        expression: "currentPage",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("view-available-vehicles", { ref: "fav" }),
      _c("batch-assign", { ref: "batchAssign" }),
      _c("date-range", { ref: "dateRange", attrs: { filter: "assignment" } }),
      _c("id-picker", {
        ref: "idPicker",
        attrs: {
          title: "Trip #",
          filterType: "assignment",
          filter: { name: "Trip #", field: "id" },
        },
      }),
      _c("decline-assignment", {
        ref: "decline",
        on: { refresh: _vm.refresh },
      }),
      _c("Dialog", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v("Driver Logs")]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "v-container",
                  { attrs: { fluid: "" } },
                  [_c("driver-log")],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.driverLogDialog,
          callback: function ($$v) {
            _vm.driverLogDialog = $$v
          },
          expression: "driverLogDialog",
        },
      }),
      _c("Dialog", {
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v("SMS Logs")]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("v-container", { attrs: { fluid: "" } }, [_c("sms-log")], 1),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.smsLogDialog,
          callback: function ($$v) {
            _vm.smsLogDialog = $$v
          },
          expression: "smsLogDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }